// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.splash-line {
  animation: fadeIn 1s ease-in-out forwards, colorChange 10s infinite;
  opacity: 0;
}

@keyframes fadeIn {
  to {
      opacity: 1;
  }
}

@keyframes colorChange {
  0% { background-color: #38bdf8; }
  25% { background-color: #38bdf8; }
  50% { background-color: #7dd3fc; }
  75% { background-color: #e0f2fe; }
  100% { background-color: #e0f2fe; }
}

`, "",{"version":3,"sources":["webpack://./src/assets/css/Splash.css"],"names":[],"mappings":"AAAA;EACE,mEAAmE;EACnE,UAAU;AACZ;;AAEA;EACE;MACI,UAAU;EACd;AACF;;AAEA;EACE,KAAK,yBAAyB,EAAE;EAChC,MAAM,yBAAyB,EAAE;EACjC,MAAM,yBAAyB,EAAE;EACjC,MAAM,yBAAyB,EAAE;EACjC,OAAO,yBAAyB,EAAE;AACpC","sourcesContent":[".splash-line {\n  animation: fadeIn 1s ease-in-out forwards, colorChange 10s infinite;\n  opacity: 0;\n}\n\n@keyframes fadeIn {\n  to {\n      opacity: 1;\n  }\n}\n\n@keyframes colorChange {\n  0% { background-color: #38bdf8; }\n  25% { background-color: #38bdf8; }\n  50% { background-color: #7dd3fc; }\n  75% { background-color: #e0f2fe; }\n  100% { background-color: #e0f2fe; }\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
