// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.neonText {
    color: #e5e5e5;
    text-shadow:
        0 0 7px #e5e5e5,
        0 0 10px #e5e5e5,
        0 0 21px #e5e5e5;

}


.animatedText {
    animation: pulsate 1.5s infinite alternate;
}

.animatedBorder {
    animation: pulsate 1.5s infinite alternate;
    border: 0.1rem solid #fff;
    border-radius: 0.4rem;
    padding: 0.4em;
    box-shadow:
        0 0 .1rem #fff,
        0 0 .2rem #fff,
        0 0 2rem #38bdf8,
        0 0 0.8rem #38bdf8,
        inset 0 0 1.3rem #38bdf8;
}

@keyframes pulsate {

    100% {

        text-shadow:
            0 0 4px #e5e5e5,
            0 0 7px #e5e5e5,
            0 0 11px #e5e5e5;

    }

    0% {

        text-shadow:
            0 0 2px #e5e5e5,
            0 0 4px #e5e5e5;
    }
}`, "",{"version":3,"sources":["webpack://./src/assets/css/Book.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd;;;wBAGoB;;AAExB;;;AAGA;IACI,0CAA0C;AAC9C;;AAEA;IACI,0CAA0C;IAC1C,yBAAyB;IACzB,qBAAqB;IACrB,cAAc;IACd;;;;;gCAK4B;AAChC;;AAEA;;IAEI;;QAEI;;;4BAGoB;;IAExB;;IAEA;;QAEI;;2BAEmB;IACvB;AACJ","sourcesContent":[".neonText {\n    color: #e5e5e5;\n    text-shadow:\n        0 0 7px #e5e5e5,\n        0 0 10px #e5e5e5,\n        0 0 21px #e5e5e5;\n\n}\n\n\n.animatedText {\n    animation: pulsate 1.5s infinite alternate;\n}\n\n.animatedBorder {\n    animation: pulsate 1.5s infinite alternate;\n    border: 0.1rem solid #fff;\n    border-radius: 0.4rem;\n    padding: 0.4em;\n    box-shadow:\n        0 0 .1rem #fff,\n        0 0 .2rem #fff,\n        0 0 2rem #38bdf8,\n        0 0 0.8rem #38bdf8,\n        inset 0 0 1.3rem #38bdf8;\n}\n\n@keyframes pulsate {\n\n    100% {\n\n        text-shadow:\n            0 0 4px #e5e5e5,\n            0 0 7px #e5e5e5,\n            0 0 11px #e5e5e5;\n\n    }\n\n    0% {\n\n        text-shadow:\n            0 0 2px #e5e5e5,\n            0 0 4px #e5e5e5;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
